* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
html {
 // font-size: 62.5%;
}
body {
//  font: 300 13px/1.6 'Helvetica Neue', Helvetica, Arial;
  color: #444;
  transition: all .4s .3s ease-in;
}
body,html{
	min-height: 100vh;
//	overflow: hidden;
}