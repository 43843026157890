//----------------------------------*\
// SCSS MIXINS
//----------------------------------*/

@import 'mixins/_position.scss';
@import 'mixins/_angle.scss';
@import 'mixins/_vertical.scss';
@import 'mixins/_gradients.scss';

//----------------------------------*\
// CONFIG MODULES
//----------------------------------*/

// Config modules
@import 'modules/_vars.scss';
@import 'modules/_clearfix.scss';
@import 'modules/_defaults.scss';
@import 'modules/_normalize.scss';
//----------------------------------*\
// PARTIALS
//----------------------------------*/

@import 'partials/_header.scss';
@import 'partials/_nav.scss';
@import 'partials/_sidebar.scss';
@import 'partials/_main.scss';
@import 'partials/_footer.scss';

//----------------------------------*\
// OBJECTS & MODULES
//----------------------------------*/

@import 'modules/_buttons.scss';
@import 'modules/_inputs.scss';
@import 'modules/_lists.scss';
@import 'modules/_typography.scss';
@import 'modules/_misc.scss';
@import 'modules/_app.scss';

// Enhancement modules
@import 'modules/_breakpoints.scss';
@import 'modules/_print.scss';

//----------------------------------*\
// VENDOR
//----------------------------------*/
@import 'vendor/_external.scss';
@import 'bootstrap/bootstrap.scss';


$green-logo: #94BA33;
$green-dark: #313E11;
$primary-font: 'Lato', Helvetica, Arial;

button,.button {
	box-shadow: none;
	background: transparent;
	border-radius: 4px;
	border: 1px solid;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	font-size: 1.25em;


}
button:hover, .button:hover{
//	opacity:0.8;
	cursor: pointer;
	text-decoration: none;
}
img {
	max-width: 100%;
}
body {
	font-family: $primary-font;
	font-weight: 300;
}
h1, h2, h3 {
	font-weight: 900;
	text-transform: uppercase;
	letter-spacing: 1px;
}
.wrap {
	@extend .container-fluid;
	.header, .footer, section {
		@extend .row;
	}
	.header {
		text-align: center;
		.pre-roll {
			@extend .col-12;
			font-size: 0.825rem;
			text-align: center;
			color: #666;
			padding-top: 0.5rem;
			background: #AEC571;
			padding-bottom: 0.5rem;
			color: white;
			@include media-breakpoint-up(md) {
				text-align: right;
			}
		}
		.logo {
			@extend .col-sm-6;
			@extend .col-md-3;
			@extend .mx-auto;
		}
		.nav {
			@extend .col-12;
			padding-bottom: 1rem;
			padding-top: 1rem;

			ul {
				padding-left: 0;
				margin-left: 0;
				display: inline-block;
				margin: 0 auto;
				.nob {
					border-right: 0 !important;
				}
				li {
					display: inline-block;
					border-right: 1px solid;
					a {
						display: block;
						margin-left: 0.25rem;
						margin-right: 0.25rem;
						text-transform: uppercase;
						font-weight: 500;
						color: $green-dark;
						font-size: 0.825rem;
						@include media-breakpoint-up(sm) {
							margin-left: 1.5rem;
							margin-right: 1.5rem;	
							font-size: 1rem;						
						}
					}
				}
			}
		}
	}
}
.about {
	.about-head{
		@extend .col-12;
		text-align: center;
		h2 {
												@include text-gradient(45deg, $green-dark, $green-logo);

		}
	}
	.about-content {
		@extend .mx-auto;
		@extend .col-lg-6;
		@extend .col-xl-4;
		@extend .col-xxl-4;
		p,h3,h4 {
			text-align: center;
		}
		p, h4 {
			margin-bottom: 1.75rem;
			line-height: 2.2;
			@include media-breakpoint-up(xxl){
				font-size: 1.5rem;
				line-height: 2.5;
			}
		}
		h3, h4 {
												@include text-gradient(45deg, $green-dark, $green-logo);

		}
	}
}
.big-hero {
	background-image:url('/assets/img/city4.jpg');
	background-repeat:no-repeat;
	background-size: cover;
	//padding-top: 20%;
	height: 66vh;
	text-align: center;
	position: relative;
  @include angle-edge($angle: 2, $angle-position-y: 'bottom', $angle-position-x: 'right');
  .section-inner{
  	width: 100%;
  	position: relative;
  }
  .big-statement {
  	@include vertical-align();
  	text-align: center;
  	width: 100%;
  	margin: 0 auto;
  	font-size: 1.25rem;
  	//line-height: 1.8;
  	color: white;
  	font-weight: 900;
  	letter-spacing: 1px;
  	//text-shadow: 0 0 15px $green-logo;
  	display: block;
  	 -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  	@include media-breakpoint-up(sm) {
  		font-size: 2rem;
  		line-height: 1.8;
  		width: 100%;
  	}
  	@include media-breakpoint-up(xxl) {
  		font-size: 4rem;
  	}
 

  }

}
.page-about {
	.big-hero {
		background-image:url('/assets/img/turq3.jpg');
		.big-statement {
			font-size: 4rem;
			  	@include media-breakpoint-up(sm) {
			  		font-size: 5.5rem;
			  	}
			  	@include media-breakpoint-up(xxl) {
			  		font-size: 11rem;
			  	}
		}
	}
}
.straight-to {
	padding-top: 60px;
	padding-bottom: 60px;
	@include media-breakpoint-up(md) {
		padding-top: 120px;
		padding-bottom: 120px;
	}
	.straight-left {
		@extend .offset-md-2;
		h2 {
			margin-bottom: 1.5rem;
			//font-size: 4em;
						//@include text-gradient(45deg, #FF6F00, #00E1FF);
									//@include text-gradient(45deg, $green-dark, $green-logo);
color: $green-logo;
						text-shadow: 2px 2px 3px rgba(255,255,255,0.1);
			@include media-breakpoint-up(sm) {
				font-size: 4em;
			}

		}
		ul {
			//margin-left: 0;
			list-style: square;
			li {
				margin-bottom: 1.5rem;
				font-size: 1.5rem;
				font-weight: 300;
				line-height: 1.8; 
				@include media-breakpoint-up(xxl) {
					font-size: 3rem;
				}
			}
			margin-bottom: 1rem;
		}
		.straight-button {
			width: 100%;
			display: block;
			//text-align: center;
			text-indent: 2.75rem;
			button,.button {
				//@include text-gradient(45deg, #FF6F00, #00E1FF);
				//color: #B24D00;
				color: $green-logo;
			}
			button:hover,.button:hover {
				color: white;
				background: $green-logo;
			}
		}
	}
	.straight-left {
		@extend .col-md-5;
		margin-right: 1rem;
		padding-bottom: 1rem;
	}
	.straight-right {
		@extend .col-md-3;
	}
	.straight-featured {
		height: 0;
		padding-bottom: 125%;
		width: 100%;
		background-image: url('/assets/img/signing.jpg');
		background-repeat:no-repeat;
		background-size: cover;
		background-position: center center;
		//  @include angle-edge($angle: 2, $angle-position-y: 'both', $angle-position-x: 'right', $angle-position-bottom-x: 'left');
		  @include media-breakpoint-up(sm) {
		  	padding-bottom: 150%;
		  }
	}
}
.why {
	padding-top: 120px;
	padding-bottom: 120px;
	color: white;
	text-align: center;
  @include angle-edge($angle: 2, $angle-position-y: 'both', $angle-position-x: 'left', $angle-position-bottom-x: 'right');
  @include gradient-directional($green-logo, #546D13, 45deg);
  h2 {
  //	font-size: 3em;
  	@include media-breakpoint-up(sm) {
  		font-size: 3rem;
  	}
  }
	.why-head {
		@extend .col-12;
		text-align: center;
		h4 {
			font-weight: 300;
			text-transform: uppercase;
			letter-spacing: 1px;
		}
	}
	.key-grid {
		@extend .col-sm-11;
		@extend .col-md-10;
		@extend .col-lg-9;
		@extend .mx-auto;
		padding-top: 3rem;
		padding-bottom: 2rem;
		.key-grid-inner {
			@extend .row;
			.key {
				@extend .col-md-4;
				padding: 0 30px !important;
				position: relative;
				img {
					border-radius: 100%;
				}
				.i-wrap-wrap {
					position: relative;
				}
				.i-wrap {
					width: 50%;
					height: 0;
					//background-color: white;
					display: block;
					padding-bottom: 25%;
					padding-top: 25%;
					margin: 0 auto;
					border-radius: 100% 100% 100% 100%;
					@include gradient-directional( #D2FF5F, #fff, 45deg);
					position:relative;
					@include media-breakpoint-up(md) {
						padding-bottom: 50%;
						padding-top: 50%;
						width: 100%
					}
				}
				i {
					font-size: 4rem;
					//background-color: white;
					color: $green-logo;
					@include vertical-align();
											text-shadow: 2px 2px 3px rgba(255,255,255,0.1);

					 top: 0; left: 0; bottom: 0; right: 0;
					 @include media-breakpoint-up(md) {
					 	font-size: 8rem;
					 }
					 @include media-breakpoint-up(xxl) {
					 	font-size: 16rem;
					 }
				}
				p {
					margin-top: 1.5rem;
					font-size: 1.125rem;
					@include media-breakpoint-up(xxl) {
						font-size: 1.525rem;
					}
				}
			}
		}
	}
	.why-button-wrap {
		@extend .col-12;
		text-align: center;
		button,.button {
			color: white;
		}
		button:hover,.button:hover{
			color: $green-logo;
			background: white;
		}
	}
}

.services {
	padding-top: 60px;
	text-align: center;
	.services-head {
		@extend .col-12;
		text-align: center;
		margin-bottom: 2rem;
		h2 {
			letter-spacing: 2px;

			//@include text-gradient(45deg, #E800AB, #00CBE8);
//			@include text-gradient(45deg, $green-dark, $green-logo);
			color: $green-logo;
			@include media-breakpoint-up(sm){
				font-size: 5rem
			}

		}
	}
	.services-grid {

		@extend .col-md-10;
		@extend .col-lg-8;
		@extend .mx-auto;
		.services-grid-inner {
			@extend .row;
			.service {
				@extend .col-sm-6;
				padding: 1rem 30px !important;

				//padding-bottom: 2rem;
				h5 {
					font-weight: 900;
					color: $green-logo;
					@include media-breakpoint-up(xxl) {
						font-size: 3rem;
					}

				}
				img {
					min-width: 100%;
				}
				p {
					font-size: 1.25rem;
					@include media-breakpoint-up(xxl) {
						font-size: 1.825rem;
					}
				}
			}
		}
	}
}
.partner {
	padding-bottom: 120px;
	padding-top: 60px;
	color: $green-logo;
	button,.button {
		color: $green-logo;
	}
	button:hover,.button:hover{
		color: white;
		background-color: $green-logo;
		text-decoration: none;
	}
	.partner-logo {
		@extend .col-md-4;
		@extend .mx-auto;
		img {
			min-width: 100%;
		}
	}
	.partner-inner {
		@extend .col-12;
		//@extend .mx-auto;
		text-align: center;
		img {
			margin-right: -15px;
		}
		h2 {
			margin-bottom: 2rem;
			color: $green-logo;
						@include text-gradient(45deg, $green-dark, $green-logo);
			@include media-breakpoint-up(md) {
				font-size: 3rem
			}
		}
	}
}
footer {
	//  @include gradient-directional(#50086D, #9333BA, 45deg);
	    @include gradient-directional($green-logo, #546D13, 45deg);

	  font-size: 1.3rem;
//	background: $green-dark;
	color: white;
	padding-top: 60px;
	padding-bottom: 60px;
	a {
		color: white;
	}
	a:hover {
		color: white;
	}
	ul {
		margin-left: 0;
		padding-left: 0;
		list-style: none outside none;
	}
	.contact-grid {
		@extend .col-md-8;
		@extend .mx-auto;
		.contact-grid-inner {
			@extend .row;
		}
		.contact-col {
			@extend .col-md-4;
			margin-bottom: 2rem;
			h6 {
				font-weight: 900;
				text-transform: uppercase;
				//border-bottom: 1px solid;
				text-decoration: underline;
			}
		}
		.contact-phone {
			text-align: center;
			@include media-breakpoint-up(md) {
				text-align: left;
			}
		}
		.contact-email {
			text-align: center;
			@include media-breakpoint-up(sm){
			}
		}
		.contact-fax {
			text-align: center;
			@include media-breakpoint-up(md) {
				text-align: right;
			}
		}
	}
}